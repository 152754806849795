import type { Effect } from '@/models/connect';
import { SystemService } from '@propify-tenant-client/services';
import type HelloSign from 'hellosign-embedded';
import type { InquiryOptions } from 'persona/dist/lib/interfaces';
import type { AnyAction } from 'redux';

export interface DropboxSign {
  testMode: boolean;
  skipDomainVerification: boolean;
  locale?: (typeof HelloSign.locales)[keyof typeof HelloSign.locales];
  clientId: string;
}

export interface SystemModelState {
  environment: string;
  personaTemplateId: string;
  personaEnvironment?: InquiryOptions['environment'];
  googlePayMerchantId: string;
  paymentCycleDays: number;
  showingsPerPaymentCycle: number;
  dropboxSignConfiguration: DropboxSign;
}

export interface SystemModelType {
  namespace: string;
  state: SystemModelState;
  effects: {
    config: Effect;
  };
  reducers: {
    setConfig: (state: SystemModelState, action: AnyAction) => SystemModelState;
  };
}

const SystemModel: SystemModelType = {
  namespace: 'system',
  state: {
    environment: '',
    personaTemplateId: '',
    googlePayMerchantId: 'BCR2DN6TWPSO3VZC',
    paymentCycleDays: 60,
    showingsPerPaymentCycle: 15,
    dropboxSignConfiguration: {
      testMode: false,
      skipDomainVerification: false,
      locale: undefined,
      clientId: '',
    },
  },
  effects: {
    *config(_, { call, put }) {
      // @ts-ignore
      const response = yield call(SystemService.getEnvironmentConfig);
      yield put({
        type: 'setConfig',
        payload: response,
      });
    },
  },
  reducers: {
    setConfig(state: SystemModelState, action: AnyAction) {
      return {
        ...state,
        environment: action.payload.environment,
        personaTemplateId: action.payload.personaTemplateId,
        personaEnvironment: action.payload.personaEnvironment,
        dropboxSignConfiguration: action.payload.dropboxSignConfiguration,
      };
    },
  },
};

export default SystemModel;
